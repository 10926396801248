import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledLink = styled(Link)`
  color: #aaa;
  font-family: Karla, sans-serif;
  font-weight: normal;
  font-size: 1.4rem;
  letter-spacing: 0.21rem;
  text-transform: uppercase;
  text-decoration: none;
  :hover {
    color: #d4a259;
  }
`;

const NavLink = ({ to, children }) => {
  const extras =
    to.indexOf(".pdf") >= 0 ? { href: to, target: "_blank", as: "a" } : { to };

  return <StyledLink {...extras}>{children}</StyledLink>;
};

export default NavLink;
