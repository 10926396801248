import React from "react";
import styled from "styled-components";
import Nav from "./nav";
import Socials from "./socials";

const Container = styled.div`
  width: 24rem;
  height: 100%;
  padding: 6rem 3rem;
  background-color: #1d1d1d;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfilePic = styled.img`
  width: 10rem;
  height: 10rem;
  border: 0.2rem solid #d4a259;
  border-radius: 100%;
  margin-bottom: 3rem;
`;

const ProfilePicLink = styled.a`
  text-decoration: none;
`;

const Site = styled.div`
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SiteTitle = styled.h1`
  font-size: 2.2rem;
  font-family: "Crimson Text", serif;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
`;

const SiteDescription = styled.h2`
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: Karla, sans-serif;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  margin: 0.4rem 0 0 0;
  text-align: center;
  line-height: 1.5rem;
`;

const links = [
  {
    text: "home",
    to: "/",
  },
  {
    text: "about",
    to: "/about",
  },
  {
    text: "My Resume (pdf)",
    to: "matt-valley-resume.pdf",
  },
  {
    text: "blog",
    to: "/blog",
  },
  {
    text: "talks",
    to: "/talks",
  },
  {
    text: "get in touch",
    to: "/get-in-touch",
  },
];

const socials = [
  {
    type: "twitter",
    url: "https://twitter.com/mattvalleycodes",
  },
];

const Header = () => (
  <Container>
    <ProfilePicLink href="/">
      <ProfilePic src="/images/profile-pic.jpg" />
    </ProfilePicLink>
    <Site>
      <SiteTitle>Matt Valley</SiteTitle>
      <SiteDescription>
        Software Developer
        <br />
        Javascript (React, Node.js)
      </SiteDescription>
    </Site>
    <Nav links={links} />
    <Socials links={socials} />
  </Container>
);

export default Header;
