import React from "react";
import styled from "styled-components";
import NavLink from "./nav-link";

const ListOfLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Link = styled.li`
  border-top: 1px solid #333;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  :last-child {
    border-bottom: 1px solid #333;
  }
`;

const Nav = (props) => {
  const links = props.links.map((link) => (
    <Link key={link.to}>
      <NavLink to={link.to}>{link.text}</NavLink>
    </Link>
  ));

  return <ListOfLinks>{links}</ListOfLinks>;
};

export default Nav;
