import React from "react";
import twitter from "./twitter.svg";
import codepen from "./codepen.svg";
import github from "./github.svg";
import instagram from "./instagram.svg";
import linkedin from "./linkedin.svg";
import photo from "./photo.svg";

const symbols = {
  twitter,
  codepen,
  github,
  instagram,
  linkedin,
  "500px": photo,
};

const Icon = ({ symbol, className }) => {
  const Symbol = symbols[symbol];

  return <Symbol className={className} />;
};

export default Icon;
