import React, { useEffect } from "react";

const Highlight = () => {
  useEffect(() => {
    window.setTimeout(() => {
      document.querySelectorAll(".wp-block-code").forEach((pre) => {
        const block = pre.querySelector("code");
        block.classList.add(pre.classList[1]);
        hljs.highlightBlock(block);
      });
    }, 1000);
  }, []);

  return <div />;
};

export default Highlight;
