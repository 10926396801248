import React from "react";
import styled from "styled-components";
import Icon from "./icon";

const Socials = styled.ul`
  list-style: none;
  margin: 2rem 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: row;
`;

const Social = styled.li`
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 0.8rem;
`;

const SocialLink = styled.a`
  cursor: pointer;
`;

const SocialIcon = styled(Icon)`
  fill: #666;
  :hover {
    fill: #d4a259;
  }
`;

const socials = [
  { type: "twitter", url: "https://twitter.com/mattvalleycodes" },
  { type: "github", url: "http://github.com/mattvalleycodes" },
  { type: "linkedin", url: "https://www.linkedin.com/in/matthew-valley/" },
  { type: "500px", url: "https://500px.com/mehdivk" },
];

const SocialNetworks = ({ links }) => (
  <Socials>
    {socials.map(({ type, url }) => (
      <Social id={type}>
        <SocialLink href={url} target="_blank">
          <SocialIcon symbol={type} />
        </SocialLink>
      </Social>
    ))}
  </Socials>
);

export default SocialNetworks;
