import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "./header";
import Highlight from "./highlight";
import "normalize.css/normalize.css";

const GlobalStyles = createGlobalStyle`
  html {
    font-family: Karla, sans-serif;
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    width: 50rem;
    margin: 2rem auto;

    .grunion-field-wrap {
      display: flex;
      flex-direction: column;
      margin: 0 0 2rem 0;
    }

    .grunion-field-label {
      margin: 0 0 1rem 0;
      font-size: 1.4rem;
    }

    .form-textbox {
      padding: 1rem;
      border: 1px solid #666;
      border-radius: 0.2rem;

    &:focus {
        outline: none;
      }
    }

    .pushbutton-wide {
      background-color: #d4a259;
      padding: 1rem 2rem;
      border: none;
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 108rem;
  margin-left: 30rem;
`;

const Title = styled.h1`
  color: #1d1d1d;
  font-family: "Crimson Text", serif;
  font-size: 4.8rem;
  line-height: 1.2;
  font-weight: 600;
  margin: 0 0 3rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 72rem;

  :after {
    content: "";
    width: 6rem;
    height: 0.1rem;
    background-color: #d4a259;
    margin-top: 1.5rem;
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  max-width: 108rem;
  height: 40rem;
  margin-bottom: 3rem;
`;

const Layout = ({ image, title, children }) => {
  return (
    <div>
      <GlobalStyles />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Crimson+Text:400,400i,600,600i|Karla:400,400i,700,700i"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/9.18.1/styles/atom-one-dark.min.css"
        />
        <script src="//cdnjs.cloudflare.com/ajax/libs/highlight.js/9.18.1/highlight.min.js"></script>
      </Helmet>
      <Highlight />
      <Header />
      <Content>
        {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
        {image && <Image src={image} />}
        {children}
      </Content>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
